import React from "react"
import "../styles/privacy.scss"
import Layout from "../components/layout"
import ScrollArrow from "../components/ScrollArrow"
import SEO from "../components/seo"
import JobForm1 from "../components/job-application-form1"

const AutomationEngineer = () => (
  <Layout>
    <ScrollArrow showBelow={450} />

    <SEO title="Automation Engineer" />
    <div className="job-vac-section">
      <div className="eng-chal-content">
        <div className="transparent">
          <div className="outer-container">
            <div className="inner-container">
              <div className="column">
                <h1>Automation Engineer</h1>
                <h2>Job Vacancy Information and Application Form</h2>
                {/* <ButtonB href="/about">Our Story</ButtonB> */}
                <h3>Contact us and send us your CV for review.</h3>
              </div>
            </div>
            <div className="outer-container">
              <div className="inner-container" style={{ textAlign: "left" }}>
                <div style={{ width: "100%" }}>
                  <h3>Job Title</h3>
                  <div className="aut-div">
                    <p>Automation Engineer</p>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <h3>Department</h3>
                  <div className="aut-div">
                    <p>Service Orchestration, Multi-Cloud Team</p>
                  </div>
                </div>

                <div style={{ width: "100%" }}>
                  <h3>Location</h3>
                  <div className="aut-div">
                    <p>
                      Flexible - working from home, office or on customer sites
                    </p>
                  </div>
                </div>

                <div>
                  <h3>Role Summary</h3>
                  <div className="aut-div">
                    <p>
                      This is an amazing opportunity for a passionate cloud,
                      virtualisation or infrastructure systems engineer. You
                      should have a wide-ranging knowledge of both solution
                      development and operations, including automation workflow
                      development, infrastructure management, system
                      administration and automation toolchains. You don't need
                      to know it all, we can teach you, but you need to be
                      self-motivated and comfortable in a start-up culture!{" "}
                    </p>
                  </div>
                </div>

                <div>
                  <h3>Duties and Responsibilities:</h3>
                  <div className="aut-div">
                    <ul>
                      <li>
                        • Solution architecture and design, implementation and
                        configuration, and systems integrations relating to
                        cloud management platforms and infrastructure automation
                        tools.{" "}
                      </li>

                      <li>
                        • Custom automation workflow development, including
                        custom scripting and API integrations{" "}
                      </li>
                      <li>
                        • Application and service requirements modelling,
                        including infrastructure-as-code, across private and
                        public cloud offerings{" "}
                      </li>
                      <li>
                        • Solution development around automated application
                        lifecycle and release management
                      </li>

                      <li>
                        {" "}
                        • Building Proof of Solutions / Proof of Value
                        demonstrations to customer requirements{" "}
                      </li>
                      <li>
                        {" "}
                        • Acting in the role of Technical Lead on customer
                        engagements where needed, taking responsibility for
                        customer requirements and project deliverables{" "}
                      </li>
                      <li>
                        {" "}
                        • New technology assessment and outcome presentation to
                        peers, with a view to becoming a subject matter expert{" "}
                      </li>
                      <li>
                        {" "}
                        • Collaboratively building innovative Metsi solutions
                        and developing associated webinars, blog content and
                        whitepapers{" "}
                      </li>
                      <li>
                        {" "}
                        • Documentation of customer solutions, process and
                        knowledge basis{" "}
                      </li>
                    </ul>
                  </div>
                </div>

                <div style={{width:"100%"}}>
                  <br />
                  <h3>Competencies:</h3>
                  <div className="aut-div">
                    <ul>
                      <li>
                        • Strong knowledge of cloud solution architectures and
                        design, for private cloud solutions and at least 1
                        public cloud provider.{" "}
                      </li>

                      <li>
                        • Strong knowledge of platform and infrastructure
                        virtualisation technologies.{" "}
                      </li>
                      <li>
                        • Additional knowledge of server and storage solutions,
                        and hyper-converged infrastructure (HCI) advantageous.{" "}
                      </li>
                      <li>
                        • Familiarity with infrastructure-as-code and
                        infrastructure automation, especially Ansible or
                        Terraform
                      </li>

                      <li>
                        {" "}
                        • Good knowledge of a scripting language and API
                        integrations (Python would be an advantage){" "}
                      </li>
                      <li>
                        {" "}
                        • Good understanding of application and software
                        lifecycles and release management{" "}
                      </li>
                      <li>
                        {" "}
                        • Experience with containers and container frameworks
                        advantageous{" "}
                      </li>
                      <li>
                        {" "}
                        • Self-starter and able to work autonomously when needed{" "}
                      </li>
                      <li>
                        {" "}
                        • Good people and communications skills - you will be in
                        front of customers{" "}
                      </li>
                      <li>
                        {" "}
                        • A structured approach to problem solving and solution
                        management{" "}
                      </li>
                      <li>
                        {" "}
                        • Experience of working in a start-up environment or
                        culture an advantage{" "}
                      </li>
                    </ul>
                  </div>
                </div>

                <div>
                  <br />
                  <h3>About Metsi</h3>
                  <div className="aut-div">
                    <p>
                      We are a fast-moving organisation at the forefront of
                      driving digital maturity in this multi-cloud world.
                      End-to-end automation is everything to us, and our
                      Engineering Team is core to building intelligent solutions
                      that drive innovation and business agility for our
                      customers. With a global presence and an emphasis on
                      employee work-life balance, Metsi provides great
                      opportunity for travel and flexible working arrangement,
                      as well as personal development and growth.{" "}
                    </p>

                    <h3>Apply Now:</h3>
                  </div>
                </div>

                <JobForm1 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default AutomationEngineer
